$black: #000000;
$yellow: #FBEE1F;
$red: #C20008;
$grey-dark: #252421;
$grey-neutral: #424042;
$grey-light: #535052;

.state {
    text-align: center;
}
.value {
    text-align: center;
}
.ReactVirtualized__Table__headerColumn  {
    color: #c5c5c5;
    text-align: center;
}

.odd-row {
    background-color: #ffffff03;
}

.even-row {

}