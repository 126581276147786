.state {
    text-align: center;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date {
    text-align: center;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.links {
    text-align: center;
    overflow: auto;
    max-height: 100px;
    padding: 8px 0px;
    line-height: 1.5;
    .link {
        display: block;
    }
}