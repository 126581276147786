$black: #000000;
$yellow: #FBEE1F;
$red: #C20008;
$grey-dark: #252421;
$grey-neutral: #424042;
$grey-light: #535052;

.layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .logo-item {
        margin-bottom: 8px;
    }
    .header-item {
        margin-bottom: 8px;
        width: 100%;
        max-width: 1024px;
    }
    .list-item {
        width: 100%;
        margin-bottom: 8px;
        max-width: 1024px;
    }
}