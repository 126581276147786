.state-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 16px 0px;
    font-size: 24px;
    .state-name {
        display: flex;
        flex: 2;
        align-self: center;
        justify-content: center;
    }
    .state-back {
        display: flex;
        flex: 1;
        align-self: center;
    }
    .filler {
        display: flex;
        flex: 1;
        align-self: flex-start;
    }
}