.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;
    .header-title {

    }
    .header-description {
        text-align: center
    }
    .header-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        .blm link {

        }
    }
}